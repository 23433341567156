/* Initial state */

.phantom-element {
  cursor: none;
}

.motion {
  opacity: 0;
  visibility: hidden;
  stroke-width: 50px;
  /* cursor: pointer; */
}

.stickman-1,
.shadow-1 {
  opacity: 1;
}

.stickman-2,
.stickman-3,
.stickman-4,
.stickman-5,
.stickman-6,
.stickman-7,
.stickman-8,
.stickman-9,
.stickman-9-blink,
.stickman-9-eyes-left,
.stickman-r-1,
.stickman-r-2,
.stickman-hips,
.shadow-2,
.shadow-3,
.shadow-4,
.shadow-5,
.shadow-6,
.shadow-7,
.shadow-8,
.shadow-9,
.shadow-morph-1,
.shadow-morph-2,
.shadow-morph-3,
.shadow-morph-4 {
  opacity: 0;
  visibility: hidden;
  cursor: none;
}

.bag-1 {
  opacity: 1;
  cursor: none;
}

.bag-2,
.bag-3,
.bag-4,
.bag-shadow-2,
.bag-shadow-3,
.bag-shadow-4,
.bag-shadow-d-1,
.bag-shadow-d-2 {
  opacity: 0;
  visibility: hidden;
}

.html5,
.css3,
.sass,
.javascript,
.api,
.npm,
.github,
.svg,
.greensock,
.react {
  transform: scale(0.7);
  visibility: hidden;
  fill: hsla(0, 0%, 20%, 1);
  /* fill: rgb(62 223 242); */
  cursor: pointer;
}

.img-overlay {
  opacity: 0;
}

.time-elapsed {
  /* background-color: var(--dark-1); */
  color: var(--dark-1);
  height: 25px;
  width: 120px;
  display: inline-block;
  position: fixed;
  /* opacity: 0;
  visibility: hidden; */
}
